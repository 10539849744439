.footer {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    // gap: 0.75rem;
    background-color: #FEEBD7;
    font-weight: 500;
    font-size: 1.25rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    // padding-left: 3rem;
    // padding-right: 3rem;

    margin-top: 1rem;

}

h1 {
    font-size: 2rem;
    font-weight: 500;
    color: #000;
}

h2{
    font-size: 1.75rem;
    font-weight: 400;
    color: #000;
}

.footer-header{
    font-size: 2rem;
}

.footer-items {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin: 0.5rem;
}

.vprasalnik-button-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 0.5rem;

    .button-c {
        font-size: 1.25rem;
        font-weight: 500;
        color: #000;
        background-color: #FEEBD7;
        border: 1px solid #000;
        border-radius: 0.5rem;
        padding: 0.5rem;
        cursor: pointer;
        user-select: none;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #000;
            color: #FEEBD7;
        }
    }
}

.footer-item {
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 6rem;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
}


@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        // gap: 0.75rem;
        // padding-bottom: 3rem;
        // padding-left: 3rem;
        // padding-right: 3rem;
    }

    .footer-items {
        display: flex;
        flex-direction: column;

        gap: 2rem;
        // margin: 1rem;
    }

    .footer-header {
        font-size: 1.5rem;
    }

    .footer-item {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        flex: 1;
    }
}