.main-container {
    font-family: Rokkitt, sans-serif;
    font-weight: 500;
    /* padding-bottom: 100px; */
    background-image: url("../../public/background.png");
    background-repeat: repeat-y;
    height: 100vh;
    /* width: 100vw; */
    background-size: cover;
    background-position: center;
}

.bottom-buttons {
    display: flex;
    /* justify-content:  */
    margin-top: 20px;
}

.custom-button {
    background: #142936;
    color: #eeeeee !important;
    border-radius: 16px;
}

.custom-button-outlined {
    background: #eeeeee;
    color: #142936;
    border-radius: 16px;
    border-color: #142936;
}

.card-styles {
    background-color: #fff6eb;
}

.meal-title{
    font-size: 1.3rem;
    font-weight: bold;
}

@media (min-width: 768px) {
    .meal-title{
        font-size: 1.8rem;
    }
}

.meal > li {
    list-style-type: disc;
    list-style-position: inside;
    font-size: 1.1rem;
}

.nested-list > li {
    /* list-style-type: disc; */
    list-style-position: inside;
    font-size: 0.95rem;
}

/* If screensize is not phone, display the menus in a flex grid style */
@media (min-width: 1000px) {
    .menus {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
}

.menu-btn{
    margin-bottom: 3rem;
}