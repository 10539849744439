
.top-left-question {
    position: absolute;
    top: 0;
    left: 0;
    height: 40% !important;
    margin: 1rem;
}


.bottom-left-question {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40% !important;
    margin: 1rem;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.top-right-question {
    position: absolute;
    top: 0;
    right: 0;
    height: 40% !important;
    margin: 1rem;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.bottom-right-question {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 40% !important;
    margin: 1rem;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.card-content{
    padding: 3rem !important;
}

@media (max-width: 768px) {
    .bottom-left-question{
        height: 22% !important;
        margin: 0.5rem !important;
    }
    .top-right-question{
        height: 22% !important;
        margin: 0.5rem !important;
    }
    .bottom-right-question{
        height: 22% !important;
        margin: 0.5rem !important;
    }
    .top-left-question{
        height: 22% !important;
        margin: 0.5rem !important;
    }
    .card-content{
        padding: 2rem 0 2rem 0rem !important;
    }
}

.height12 {
    height: 12% !important;
}

.height40{
    height: 40% !important;
}