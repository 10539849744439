.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000a7;
    display: flex;
    align-items: baseline;
    justify-content: center;
}