.navbar-container {
    /* Navbar display settings */
    display: flex;
    justify-content: space-between;
    color: #000000;
    background-color: #FEEBD7;
    height: 3rem;

    .navbar-button {
        font-size: larger;
        background-color: transparent;
    }


    .left,
    .right {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        color: black;
        gap: 0.75rem;
    }

    .left {
        margin-left: 1rem;
    }

    .right {
        margin-right: 1rem;
    }

    .center {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .navbar-container {
        height: 2.7rem;

        .navbar-button {
            font-size: large;
        }
    }
}
