@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@200;300;400;500&display=swap");

.container-custom {
    font-family: Rokkitt, sans-serif;
    font-weight: 500;
    /* padding-bottom: 100px; */
    background-image: url("../../public/background.png");
    background-repeat: repeat-y;
    height: 100%;
    /* width: 100vw; */
    background-size: cover;
    background-position: center;
}

.mainImageContainer {
    background-image: url("../assets/background.png");
    /* background-image: url("../../public/MainImgDesktop.png"); */
    /* width: 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.mainImageContainerContent {
    padding-bottom: 0px;
    padding-top: 0px;
}

@media (min-width: 768px) {
    .mainImageContainerContent {
        padding-bottom: 100px;
        padding-top: 20px;
    }
}

@media (min-width: 1400px) {
    .mainImageContainerContent {
        padding-bottom: 100px;
        padding-top: 0px;
    }
}

@media(min-wdith: 1500px) {
    .mainImageContainerContent {
        padding-bottom: 100px;
        padding-top: 00px;
    }
}

.people-container {
    margin-top: 16px;
    margin-bottom: 48px;
}

.event-item{
    font-size: 1.1rem;
    gap: 1rem;
}

/* Get first div in event-item and make it align left */
.event-item > div:first-child {
    margin-right: auto;
}

@media (min-width: 768px) {
    .event-item{
        font-size: 1.5rem;
    }
}

.dogajanje-container{
    background-color: rgb(255, 255, 255);
    margin: 0rem 6rem 0rem 6rem;
    padding: 1rem 0.8rem 1rem 0.8rem;
    font-size: small;
    /* border: black 1px solid; */
    /* border-radius: 16px; */
    box-shadow: 6px 14px 5px 6px #ccc;
}



.bottom-banner {
    margin: 0rem 6rem 4rem 6rem;
    padding-bottom: 80px;
    background-color: transparent;
    background-image: url("../assets/BottomBanner.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}

.top-banner {
    margin: 4rem 6rem 0rem 6rem;
    height: 10px;
    background-color: transparent;
    background-image: url("../assets/TopBanner.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

@media (max-width: 768px) {
    .dogajanje-container {
        margin: 0rem 4rem 0rem 4rem;
    }
    .bottom-banner {
        margin: 0rem 4rem 4rem 4rem;
        /* background-size: fit; */
        padding-bottom: 60px;
        background-image: url("../assets/BottomBanner-Small.png");
    }
    .top-banner {
        margin: 4rem 4rem 0rem 4rem;
    }
}

@media (max-width: 568px) {
    .dogajanje-container {
        margin: 0rem 1rem 0rem 1rem;
    }
    .bottom-banner {
        margin: 0rem 1rem 4rem 1rem;
        /* background-size: fit; */
        padding-bottom: 60px;
        background-image: url("../assets/BottomBanner-Small.png");
    }

    .top-banner {
        margin: 4rem 1rem 0rem 1rem;
    }
}

.mobile-people-container {
    overflow: hidden;
    margin-bottom: 48px;
    margin-top: 16px;
}

.batn {
    font-weight: bold;
    height: 3rem;
    /* bottom: 1.5rem; */
    padding: 0 2rem 0 2rem;
    border-radius: 16px;
    /* min-height: 4rem; */
    margin: 2rem 2rem 0 2rem;
    background: #142936;
    color: #eeeeee;
    z-index: 100;
    font-size: 1rem;
}

.batn:hover {
    background: #142936;
    color: #afafaf;
}

.line {
    border-top: 2px solid #00000036;
    width: 80%;
    margin: 0 auto;
    /* margin-top: 20px;
    margin-bottom: 20px; */
}

.reverse-container {
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) {
    .reverse-container {
        flex-direction: column-reverse;
    }
}

.info-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.second-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    color: white;
    font-size: 2.2rem;
}

/* Media query if mobile */
@media (max-width: 768px) {
    .second-img {
        font-size: 1.1rem;
    }
}

.title-text {
    filter: drop-shadow(0.1em 0.1em 0.1em #282727);
    padding-top: 10rem;
}
.subtitle-text {
    filter: drop-shadow(0.5em 0.5em 0.5em #28272757);
}

@media (min-width: 768px) {
    .title-text {
        padding-top: 24rem;
    }
}

.avatar-pic {
    height: 14em;
    width: 14em;
    /* object-fit: cover; */
}
.avatar-pic-dogodek {
    height: fit-content;
    width: 12em;
    /* object-fit: cover; */
}

@media (max-width: 768px) {
    .avatar-pic {
        width: 10em;
        height: 100%;
    }
}
@media (max-width: 768px) {
    .avatar-pic-dogodek {
        width: 10em;
        height: 100%;
    }
}

.x-sign {
    height: 2.5em;
    width: 2.5em;
    padding: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    /* object-fit: cover; */
}

.paragrapf {
    font-size: 1.1em;
}
