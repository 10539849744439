/* .containerz {
    background-color: #fff;
    width: 25rem;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px;
    color: #000;
    border-radius: 20px;
} */

.card {
    /* width: 60rem; */
    /* height: 30rem; */
    /* min-height: 100%; */
    /* margin: 2rem; */
}

.center-text {
    text-align: center;
}

/* .field-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
} */

/* input[type="text"]{
    border:none;
    border-bottom: 1px solid #000;
} */

.form-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; */
    margin: 4rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}


@media (max-width: 768px) {
    .labels-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.sleeping-info {
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
}

.sleeping-label {
    margin-bottom: 0rem !important;
}

.custom-button {
    background: #142936;
    color: #eeeeee !important;
    border-radius: 16px;
}

.show-menu-btn {
    font-size: 0.8rem !important;

}

.prehrana-container {
    display: flex;
    flex-direction: row;
}

.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-title {
    margin-top: 3rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}
.center {
    text-align: center;
}

@media (max-width: 768px) {
    .control-menu {
        width: 90%;
    }
}
