.modal {
    width: clamp(40%, 700px, 90%);
    height: min(50%, 300px);

    margin: auto;
    padding: 2rem 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: hsl(54, 45%, 78%); */
    background-color: #FEEBD7;
    /* background-color: hsla(54, 45%, 78%, 0.848); */
}

/* .orange-gradient {
    background: linear-gradient(15deg, #486dff, #31d4fd);
} */

.modal-button {
    /* position: relative; */
    min-width: 6rem;
    /* bottom: 1.5rem; */
    padding: 0 1.5rem 0 1.5rem;
    border-radius: 16px;
    /* min-height: 4rem; */
    margin: 2rem 2rem 0 2rem;
    background: #142936;
    color: #eeeeee;
    z-index: 100;
    /* font-size: 0.9rem; */
}

.button-div {
    display: flex;
    flex-direction: row;
}

.modal-text {
    /* margin: 1rem 0.5rem 1rem 0.5rem; */
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #191818;
}

.top-left {
    position: absolute;
    top: 0;
    left: 0;
    height: 45%;
    margin: 0.25rem;
}

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 45%;
    margin: 0.25rem;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
    height: 45%;
    margin: 0.25rem;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 45%;
    margin: 0.25rem;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

@media (max-width: 768px) {
    .container {
        padding-right: 16px !important;
        padding-left: 16px !important;
    }
    .modal {
        width: 90%;
        height: fit-content;
        padding: 4rem 2.5rem;
    }

    .button-div {
        padding-top: 1rem;
        flex-direction: column;
    }

    .modal-button {
        margin: 0.5rem 0 1rem 0;
        font-size: 0.9rem;
    }

    .modal-text {
        font-size: 1.05rem;
        margin-bottom: 1rem;
    }
}
