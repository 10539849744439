.timer-container {
    font-family: Rokkitt, sans-serif;
    font-size: 2.4rem;
    font-weight: 400;
    /* margin-top: 8rem; */
    /* padding-bottom: 100px; */
    /* background-image: url("../../public/background.png"); */
    /* background-repeat: repeat-y; */
    /* height: 100%; */
    /* width: 100vw; */
    /* background-size: cover; */
    background-position: center;
}

@media (min-width: 768px) {
    .timer-container {
        font-size: 4rem;
    }
}

.text-white {
    color: #fff;
}